<template>

  <div class="body-view">

    <div>
      <menu-view v-if="showMenu" :setMenuCollapsed="setMenuCollapsed"/>
    </div>
    <el-main :class="{ 'el-main-with-menu': showMenu, 'el-main': !showMenu }">
      <router-view :class="{ 'router-view-login': !isUserOnline, 'active-router-view': isMenuCollapsed, 'router-view': !isMenuCollapsed }"/>
    </el-main>

  </div>

</template>

<script>

  import { isUserLogged } from '../util/UserUtils';
  import { CREATE_USER_PATH } from '../constants/RoutesConstants';
  import MenuView from '../layouts/Menu.vue';

  export default {
    name: 'body-view',
    components: { 'menu-view': MenuView },
    data() {
      return {
        isUserOnline: false,
        isMenuCollapsed: false
      }
    },
    mounted() {
      this.createEmitToUpdateUserStatus();
    },
    methods: {
      setMenuCollapsed(isMenuCollapsed) {
        this.isMenuCollapsed = isMenuCollapsed;
      },
      createEmitToUpdateUserStatus() {
        this.isUserOnline = isUserLogged();
        this.emitter.on('setUserStatus', (status) => {
          this.isUserOnline = status;
        });
        if(!this.isUserOnline) {
          document.title = "Personal Invest";
        }
      },
      isAllowedRoutePath() {
        return this.$router.currentRoute.value.path === CREATE_USER_PATH;
      }
    },
    computed: {
      showMenu() {
        return this.isUserOnline;
      }
    }
  }

</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .el-main-with-menu {
      text-align: center;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-left: 20px;
    }

    .router-view {
      width: 88.5%;
      float: right;
      transition: 0.5s;
    }

    .active-router-view {
      width: 92.5%;
      float: right;
      transition: 0.5s;
    }

    .body-view {
      min-height: calc(100vh - 132px) !important;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .el-main-with-menu {
      text-align: center;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-left: 100px;
    }

    .router-view {
      width: 88.5%;
      float: right;
      transition: 0.5s;
    }

    .active-router-view {
      width: 98.5%;
      float: right;
      transition: 0.5s;
    }

    .body-view {
      min-height: calc(100vh - 220px) !important;
    }

  }

  .router-view-login {
    padding-left: 20px;
    width: 100%;
  }

  .body-view {
    text-align: center;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .el-main {
    text-align: center;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

</style>
