<template>

  <div class="dashboard-summary-section">
    <el-tooltip class="box-item" effect="dark" content="Atualizar resumos" placement="top">
      <el-button type="primary" class="refresh" :disabled="refreshButtonDisabled" @click="getSummary()" @mouseover="mouseOverRefresh = true" @mouseleave="mouseOverRefresh = false">
        <el-icon><Refresh/></el-icon>
      </el-button>
    </el-tooltip>
    <div :class="getMouseOverRefresClass" v-loading="mouseOverRefresh" class="table-responsive" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">
      <section-row :isFirstSection="true">
        <company-summary-banner-section v-if="allListsLoaded" :companiesSummary="summary.companiesSummary"/>
      </section-row>
      <section-row>
        <capital-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :capitalSummary="summary.capitalSummary"/>
      </section-row>
      <section-row>
        <company-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :companiesSummary="summary.companiesSummary"/>
      </section-row>
      <section-row>
        <wallet-summary-section v-if="allListsLoaded" :tableLoading="tableLoading" :walletSummary="summary.walletSummary"/>
      </section-row>
    </div>
  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import CompanySummaryBannerSection from '@/section/dashboard/tab/summary/items/CompanySummaryBannerSection';
import CapitalSummarySection from '@/section/dashboard/tab/summary/items/CapitalSummarySection';
import WalletSummarySection from '@/section/dashboard/tab/summary/items/WalletSummarySection';
import CompanySummarySection from '@/section/dashboard/tab/summary/items/CompanySummarySection';
import { getSummary } from '@/http/bff/dashboard-bff-service';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';

export default {
  name: 'dashboard-summary-section',
  components: { SectionRow, CompanySummaryBannerSection, CapitalSummarySection, WalletSummarySection, CompanySummarySection },
  mounted() {
    document.title = "Dashboard - Personal Invest";
  },
  data() {
    return {
      tableLoading: false,
      allListsLoaded: false,
      summary: {
        walletSummary: {
          walletType: [],
          walletTypesPendingTotal: 0,
          walletTypesTotal: 0,
          walletTotal: 0
        },
        companiesSummary: {
          companiesTypes: []
        }
      },
      refreshButtonDisabled: false,
      mouseOverRefresh: false,
      loadingSvg: DEFAULT_LOADING_SVG
    }
  },
  methods: {
    getSummary() {
      this.refreshButtonDisabled = true;
      this.tableLoading = true;
      this.mouseOverRefresh = true;
      getSummary().then(response => {
        this.summary = response.data;
        this.tableLoading = false;
        this.allListsLoaded = true;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;
      }).catch(() => {
        this.tableLoading = false;
        this.refreshButtonDisabled = false;
        this.mouseOverRefresh = false;
      });
    }
  },
  computed: {
    getMouseOverRefresClass() {
      return this.mouseOverRefresh === true ? "mouse-on-refresh" : "mouse-off-refresh";
    }
  },
  created() {
    this.getSummary();
  }
}
</script>

<style lang="scss" scoped>

  .dashboard-summary-section {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .refresh {
    color: $default-button-color;
    height: 30px;
    width: 30px;
    margin-left: 5px;
    margin-top: 20px;
  }

  .mouse-off-refresh {
    opacity: 1;
  }

  .mouse-on-refresh {
    opacity: 0.5;
  }

</style>