<template>

    <div class="user-section">

        <div class="logged-user">
            <el-menu-item index="3-1" :class="{ 'active-menu-item': isUserProfilePath, 'menu-item': !isUserProfilePath }" @click="goToUpdateUser()"> <el-icon><User/></el-icon> Meu perfil </el-menu-item>
            <el-menu-item index="3-2" class="menu-item" @click="logout()"> <el-icon><Close/></el-icon> Sair </el-menu-item>
        </div>

    </div>

</template>

<script>

    import { USER_PROFILE_PATH } from '@/constants/RoutesConstants';

    export default {
        name: 'user-section',
        props: {
            logout: Function,
            goToUpdateUser: Function
        },
        computed: {
            isUserProfilePath() {
                return this.$route.path === USER_PROFILE_PATH;
            }
        }
    }

</script>

<style lang="scss" scoped>

    .user-section {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: inherit;
        font-weight: bold;
        color: white !important;
        padding: 0px;
    }
    
    .el-menu--horizontal .el-menu .el-menu-item {
        background-color: $default-layout-color;
        color: $default-header-text-color;
    }

    .el-menu--horizontal .el-menu .el-menu-item:hover {
        background-color: $default-layout-color !important;
    }

    .menu-item:hover {
        color: white !important;
    }

    .active-menu-item {
        color: white !important;
    }

</style>
