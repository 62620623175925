<template>

    <Dialog class="create-or-update-dialog" :visible="recurrenceCreateAuxVisible" headerText="Criar agendamento">
        <template #body>
            <create-recurrence-dialog @closeCreateRecurrenceDialog="closeCreateRecurrenceDialog()" @getPendingOrdersRecurrenceConfig="getPendingOrdersRecurrenceConfig()" :dollarValue="dollarValue"/>
        </template>
    </Dialog>

    <confirm-dialog class="create-or-update-dialog" :visible="recurrenceUpdateAuxVisible"
        headerText="Editar agendamento"
        confirmText="Confirmar"
        cancelText="Cancelar"
        :isConfirmButtonDisabled="!validUpdateRequest(recurrenceAux)"
        @confirm="updateRecurrence()"
        @cancel="closeUpdateRecurrenceDialog()">
        <template #body>
            <update-recurrence-dialog v-if="recurrenceAux != null" :recurrenceToUpdate="recurrenceAux" :dollarValue="dollarValue"/>
        </template>
    </confirm-dialog>

    <confirm-dialog :visible="recurrenceCancelAuxVisible"
        headerText="Confirmação"
        confirmText="Confirmar"
        cancelText="Cancelar"
        @confirm="deleteRecurrence()"
        @cancel="closeCancelRecurrenceDialog()">
        <template #body>
            <span> Realmente deseja cancelar o agendamento? </span>
        </template>
    </confirm-dialog>

    <div class="pending-orders-recurrence-section">

        <h3 style="margin-top: 0px; text-transform: uppercase">
            Ordens pendentes
            <el-tooltip class="box-item" effect="dark" content="Agendamentos mensais para criação de ordens pendentes" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div class="table-buttons">
            <el-button type="success" :disabled="recurrences.length >= 10" @click="setRecurrenceCreateAux()"> Criar </el-button>
        </div>
        <div class="recurrences-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Categoria </th>
                        <th> Ativo </th>
                        <th> Quantidade </th>
                        <th> Preço </th>
                        <th> Total </th>
                        <th> Preço de mercado </th>
                        <th> Próxima execução </th>
                        <th> Última execução </th>
                        <th> Criada em </th>
                        <th> Ações </th>
                    </tr>
                </thead>
                <tbody v-if="recurrences.length > 0">

                    <tr v-for="recurrence in recurrences" :key="recurrence">

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span :class="recurrence.tagTypeClassName"> {{ getCompanyType(recurrence.company.type) }} </span>
                            </el-tag>
                        </td>

                        <td>
                            <div class="image-name" @click="goToCompanyDetails(recurrence.company.name)">
                                <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                    <img v-if="recurrence.company.imageUrl != null" style="margin-right: 10px" :src="recurrence.company.imageUrl" width="30" height="20">
                                    <span :class="recurrence.tagTypeClassName"> {{ recurrence.company.name }} </span>
                                </el-tag>
                            </div>
                        </td>
                        
                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span> {{ recurrence.amount }} </span>
                            </el-tag>
                        </td>
                        
                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <el-tooltip class="box-item" effect="dark" content="Preço de mercado" placement="top">
                                    <span> {{ getFormattedRecurrencePrice(recurrence) }} </span>
                                </el-tooltip>
                            </el-tag>
                        </td>

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span> {{ getFormattedTotal(recurrence) }} </span>
                            </el-tag>
                        </td>

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span> {{ recurrence.atMarketValue ? 'Sim' : 'Não' }} </span>
                            </el-tag>
                        </td>

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span> {{ getFormattedDate(recurrence.scheduleDate) }} </span>
                            </el-tag>
                        </td>

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span v-if="recurrence.lastExecutedAt !== null"> {{ getFormattedDateTime(recurrence.lastExecutedAt) }} </span>
                                <span v-if="recurrence.lastExecutedAt === null"> Ainda não executada </span>
                            </el-tag>
                        </td>

                        <td>
                            <el-tag :key="recurrence.id" class="mx-1 table-tag" :disable-transitions="false">
                                <span> {{ getFormattedDateTime(recurrence.createdAt) }} </span>
                            </el-tag>
                        </td>

                        <td>
                            <div class="actions">
                                <el-tooltip class="box-item" effect="dark" content="Editar agendamento" placement="top">
                                    <el-button type="info" icon="Edit" circle class="action-button" @click="setRecurrenceUpdateAux(recurrence)"/>
                                </el-tooltip>
                                <el-tooltip class="box-item" effect="dark" content="Remover agendamento" placement="top">
                                    <el-button type="danger" icon="Delete" circle class="action-button" @click="setRecurrenceCancelIdAux(recurrence.id)"/>
                                </el-tooltip>
                            </div>
                        </td>
                        
                    </tr>

                    <tr>
                        <td class="total-row"> GERAL </td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                        <td class="total-row"> {{ recurrencesTotal }} </td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                        <td class="total-row"></td>
                    </tr>

                </tbody>

            </table>

            <div v-if="recurrences.length === 0">
                <EmptyResult></EmptyResult>
            </div>

        </div>

    </div>

</template>

<script>

import { getPendingOrdersRecurrenceConfig, updateRecurrence, deleteRecurrence } from '@/http/bff/planning-bff-service';
import EmptyResult from '@/component/common/EmptyResult';
import { DEFAULT_LOADING_SVG, DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL } from '@/constants/AppConstants';
import CompanyType from '@/constants/CompanyType';
import { BLANK_PAGE_ARG } from '@/constants/AppConstants';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
import { getFormattedValue } from '@/util/MoneyUtils';
import { ElMessage } from 'element-plus';
import ConfirmDialog from "@/component/common/ConfirmDialog";
import Dialog from "@/component/common/Dialog";
import CreateRecurrenceDialog from "./dialog/CreateRecurrenceDialog";
import UpdateRecurrenceDialog from "./dialog/UpdateRecurrenceDialog";

export default {
    name: "pending-orders-recurrence-section",
    components: { EmptyResult, Dialog, ConfirmDialog, CreateRecurrenceDialog, UpdateRecurrenceDialog },
    props: {
        dollarValue: Number
    },
    data() {
        return {
            pendingOrdersRecurrenceConfig: {
                recurrences: []
            },
            tableLoading: false,
            loadingSvg: DEFAULT_LOADING_SVG,
            CompanyType,
            DEFAULT_STOCK_INITIAL_VALUE,
            DEFAULT_STOCK_DECIMAL,
            recurrenceCreateAuxVisible: false,
            recurrenceUpdateAuxVisible: false,
            recurrenceCancelAuxVisible: false,
            recurrenceAux: {},
            recurrenceAuxId: null
        }
    },
    created() {
        this.getPendingOrdersRecurrenceConfig();
    },
    mounted() {
        document.title = "Planejamento - Personal Invest";
    },
    methods: {
        deleteRecurrence() {

            if(this.recurrenceAuxId !== null) {

                deleteRecurrence(this.recurrenceAuxId).then(() => {
                    ElMessage({
                        message: "Agendamento removido com sucesso!",
                        type: 'success'
                    });
                    this.closeCancelRecurrenceDialog();
                    this.getPendingOrdersRecurrenceConfig();
                }).catch(() => {
                    ElMessage({
                        message: "Agendamento não pode ser removido, tente novamente mais tarde!",
                        type: 'error'
                    });
                });

            }

        },
        closeCancelRecurrenceDialog() {
            this.recurrenceCancelAuxVisible = false;
        },
        closeCreateRecurrenceDialog() {
            this.recurrenceCreateAuxVisible = false;
        },
        updateRecurrence() {

            if(this.validUpdateRequest(this.recurrenceAux)) {

                updateRecurrence(this.recurrenceAux.id, this.recurrenceAux).then(() => {
                    ElMessage({
                        message: "Agendamento atualizado com sucesso!",
                        type: 'success'
                    });
                    this.closeUpdateRecurrenceDialog();
                    this.getPendingOrdersRecurrenceConfig();
                }).catch(() => {
                    ElMessage({
                        message: "Agendamento não pode ser atualizado, tente novamente mais tarde!",
                        type: 'error'
                    });
                });

            }

        },
        closeUpdateRecurrenceDialog() {
            this.recurrenceUpdateAuxVisible = false;
        },
        validUpdateRequest(recurrence) {

            if(recurrence.company === null || recurrence.company === undefined || recurrence.company.id === null || recurrence.company.id === "") {
                return false;
            }

            if(recurrence.id === null || recurrence.id === undefined) {
                return false;
            }

            if(recurrence.amount === null || recurrence.amount === undefined || recurrence.amount === "" || recurrence.amount <= 0) {
                return false;
            }

            if(recurrence.price === null || recurrence.price === undefined || recurrence.price === "" || recurrence.price <= 0) {
                return false;
            }

            if(recurrence.atMarketValue === null || recurrence.atMarketValue === undefined) {
                return false;
            }

            if(recurrence.scheduleDate === null || recurrence.scheduleDate === undefined) {
                return false;
            }

            return true;

        },
        setRecurrenceCreateAux() {
            this.recurrenceCreateAuxVisible = true;
        },
        setRecurrenceUpdateAux(recurrence) {
            this.recurrenceAux = recurrence;
            this.recurrenceUpdateAuxVisible = true;
        },
        setRecurrenceCancelIdAux(id) {
            this.recurrenceAuxId = id;
            this.recurrenceCancelAuxVisible = true;
        },
        getPendingOrdersRecurrenceConfig() {
            getPendingOrdersRecurrenceConfig().then(response => {
                this.pendingOrdersRecurrenceConfig = response.data;
            });
        },
        getFormattedRecurrencePrice(recurrence) {

            if(recurrence != null && recurrence != undefined && recurrence.price !== null && recurrence.company.type != null && recurrence.company.type != undefined) {
                const locale = CompanyType[recurrence.company.type].locale;
                return getFormattedValue(recurrence.price, locale);
            }

            return 'N/A';

        },
        getFormattedTotal(recurrence) {

            if(recurrence != null && recurrence != undefined && recurrence.amount !== null && recurrence.price !== null && recurrence.company.type != null && recurrence.company.type != undefined) {

                const locale = CompanyType[recurrence.company.type].locale;
                const total = recurrence.amount * recurrence.price;

                return getFormattedValue(total, locale);

            }

            return '-';

        },
        isDisabledClass(atMarketValue) {
            return atMarketValue === true ? "price-disabled" : "";
        },
        getCompanyAmountClass(type) {
            return type !== this.CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";
        },
        getCompanyType(type) {
            return CompanyType[type].description;
        },
        getFormattedDate(dateToFormat) {

            const date = new Date(dateToFormat);
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1;
            let dd = date.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return dd + '/' + mm + '/' + yyyy;

        },
        getFormattedDateTime(dateToFormat) {

            const date = new Date(dateToFormat);
            const yyyy = date.getFullYear();
            let mm = date.getMonth() + 1;
            let dd = date.getDate();
            const hour = date.getHours();
            const minutes = date.getMinutes();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            return dd + '/' + mm + '/' + yyyy + " " + hour + ":" + minutes;

        },
        getCompanyCurrency(company) {

            if(company.type == CompanyType.STOCK.value) {
            return { currency: "USD", };
            }

            return { currency: "BRL" };

        },
        goToCompanyDetails(name) {
            const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + name.toLowerCase();
            window.open(formattedUrl, BLANK_PAGE_ARG).focus();
        }
    },
    computed: {
        recurrences() {
            this.pendingOrdersRecurrenceConfig.recurrences.forEach(recurrence => {

                recurrence.typeClassName = CompanyType[recurrence.company.type].className;
                recurrence.tagTypeClassName = CompanyType[recurrence.company.type].tagTypeClassName;

                if(recurrence.atMarketValue === true && recurrence.currentPrice !== null) {
                    recurrence.price = recurrence.currentPrice;
                }

            });
            return this.pendingOrdersRecurrenceConfig.recurrences;
        },
        recurrencesTotal() {
            const total = this.recurrences.length > 0 ? 
                this.recurrences.map(recurrence => {

                    if(recurrence.amount !== null && recurrence.price !== null) {

                        let total = recurrence.amount * recurrence.price;
                        if(recurrence.company.type !== null && this.dollarValue !== null && this.dollarValue !== undefined && recurrence.company.type === CompanyType.STOCK.value) {
                            total = total * this.dollarValue;
                        }

                        return total;

                    }

                    return 0;

                }).reduce((partialSum, a) => partialSum + a, 0) :
                0;
            return getFormattedValue(total, "pt_br");
        }
    }
}

</script>

<style lang="scss" scoped>
    
    .pending-orders-recurrence-section {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .recurrences-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .table-buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        justify-content: flex-end;
    }

    .image-name {
        cursor: pointer;
    }

    .price-disabled {
        opacity: 0.6;
    }

    @media screen and (max-width: 1024px) {

        .recurrences-table {
            overflow: auto;
        }

        .price-input {
            width: 100% !important;
            font-size: 8px;
        }

        .table-tag {
            width: 80%;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .table-tag {
            width: 70%;
        }

        .create-or-update-dialog {
            width: 40%;
        }

    }

</style>
