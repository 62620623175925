<template>

  <div class="user-profile">
    
    <h1> Atualizar perfil </h1>
    
    <br>

    <div class="common-el-input">
      <h3> Deseja alterar sua senha? </h3>
      <el-button v-loading="loginLoading" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" type="success" @click="resetPassword()" class="reset-button"> Clique aqui </el-button>
    </div>

    <el-divider></el-divider>

    <div v-if="this.user" class="form">

      <div class="photo-el-input">

        <h3> Foto de perfil </h3>

        <div class="update-el-input">

          <el-upload v-model:file-list="fileList" accept="image/png" class="upload-demo" :on-change="uploadImage" :auto-upload="false" :limit="1" list-type="picture">
            <el-avatar :size="100" :src="getUserPhoto" class="avatar"/>
            <template #file>
              <i class="el-icon el-icon--close" @click="removeImage()">
                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                  </path>
                </svg>
              </i>
                {{ fileName }}
            </template>
          </el-upload>

        </div>

      </div>

      <el-divider direction="vertical"></el-divider>

      <div class="common-el-input">

        <h3> Usuário </h3>

        <div class="update-el-input">
          <span> Username </span>
          <el-input type="text" @change="initFieldEdit('username')" minlength="1" maxlength="255" v-model="user.username" :class="invalidUsernameFieldValue()" class="name-el-input"/>
        </div>

        <h3> Informações pessoais </h3>

        <div class="update-el-input">
          <span> Primeiro nome </span>
          <el-input type="text" @change="initFieldEdit('firstName')" minlength="1" maxlength="255" v-model="user.firstName" :class="invalidFirstNameFieldValue()" class="name-el-input"/>
          <i> Começa com letra maiúscula </i>
        </div>

        <div class="update-el-input">
          <span> Sobrenome </span>
          <el-input type="text" @change="initFieldEdit('lastName')" minlength="1" maxlength="255" v-model="user.lastName" :class="invalidLastNameFieldValue()" class="name-el-input"/>
          <i> Começa com letra maiúscula </i>
        </div>

        <div class="update-el-input">
          <span> CPF: </span>
          <el-input type="text" @change="initFieldEdit('socialId')" :class="invalidSocialIdFieldValue()" minlength="1" maxlength="255" v-maska data-maska="###.###.###-##" placeholder="123.456.789-10" v-model="user.socialId" class="name-el-input"/>
        </div>

        <div class="update-el-input">
          <span> E-mail </span>
          <el-input type="text" @change="initFieldEdit('email')" :class="invalidEmailFieldValue()" minlength="1" maxlength="255" v-model="user.email" placeholder="foo@foo.com" class="name-el-input"/>
        </div>

        <div class="foot-buttons">
          <el-button type="success" :disabled="!validRequest(user)" @click="updateUser()"> Confirmar </el-button>
        </div>

      </div>

    </div>

    <el-divider></el-divider>

    <div class="company-config-input">

      <div class="company-config-table">

        <h3>
          Limites
          <el-tooltip class="box-item" effect="dark" content="Limites planejados por categoria" placement="top">
              <el-icon class="info-icon"><InfoFilled/></el-icon>
          </el-tooltip>
        </h3>
        <table v-loading="configTableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">

          <thead>
              <tr>
                  <th> Categoria </th>
                  <th> Limite mensal </th>
                  <th> % </th>
                  <th> Ações </th>
              </tr>
          </thead>
          <tbody v-if="userCompanyConfigList.length > 0">
              <tr v-for="companyConfig in userCompanyConfigList" :key="companyConfig">
                  <td style="font-weight: bold;">
                    <el-tag :key="companyConfig.type" class="mx-1 table-tag" :disable-transitions="false">
                      <span :class="companyConfig.tagTypeClassName"> {{ getType(companyConfig.type) }} </span>
                      <el-tooltip v-if="companyConfig.type === CompanyType.STOCK.value" class="box-item" effect="dark" :content="getDollarValueFormatted()" placement="top">
                        <el-icon class="info-icon"><InfoFilled/></el-icon>
                      </el-tooltip>
                    </el-tag>
                  </td>
                  <td>
                      <currency-input v-model="companyConfig.monthLimit" :options="getPtCurrency()" @change="companyConfig.edited = true" class="price-input"/>
                  </td>
                  <td> {{ getCompanyConfigPercentage(companyConfig) }} </td>
                  <td>

                      <div class="actions">
                          <div v-if="companyConfig.edited" class="actions">
                              <el-tooltip class="box-item" effect="dark" content="Confirmar edição" placement="top">
                                  <el-button type="success" icon="Check" @click="updateUserCompaniesConfig(companyConfig)" circle class="action"/>
                              </el-tooltip>
                              <el-tooltip class="box-item" effect="dark" content="Cancelar edição" placement="top">
                                  <el-button type="danger" icon="Delete" @click="getUserCompaniesConfig()" circle class="action"/>
                              </el-tooltip>
                          </div>
                          <div v-if="!companyConfig.edited">
                              -
                          </div>
                      </div>
                  </td>
              </tr>
              <tr class="total-row">
                <td> TOTAL </td>
                <td> {{ getFormattedValue(userCompanyConfigTotal, "pt_br") }}  </td>
                <td> 100% </td>
                <td> - </td>
              </tr>
          </tbody>

        </table>

    </div>

</div>

  </div>

</template>

<script>

  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { getUserIdentifierFromCache, refreshTokenAndGetUserInfo } from '@/util/UserUtils';
  import { sendResetPasswordToken } from '@/http/user/anonymous-user-service';
  import { getUserInfo, updateUser, getUserCompaniesConfig, updateUserCompaniesConfig } from '@/http/bff/user-bff-service';
  import { convertBase64ToImage } from '@/util/AppUtils';
  import { DEFAULT_AVATAR_IMAGE_URL } from '@/constants/AppConstants';
  import { ElMessage } from 'element-plus'
  import { getFormattedValue } from '@/util/MoneyUtils';
  import CurrencyInput from "@/component/common/CurrencyInput";
  import CompanyType from '@/constants/CompanyType';

  export default {
    name: 'user-profile-view',
    components: { CurrencyInput },
    data() {
      return {
        user: {},
        imageChanged: false,
        fileName: "",
        fileList: [],
        CompanyType,
        companiesTypesConfigs: {
            companyTypeConfig: [],
            total: 0
        },
        getFormattedValue,
        fields: {
          username: false,
          firstName: false,
          lastName: false,
          socialId: false,
          email: false
        }
      }
    },
    created() {
      this.getUser();
    },
    methods: {
      initFieldEdit(field) {
        this.fields[field] = true;
      },
      invalidUsernameFieldValue() {

        if(!this.fields.username) {
          return "";
        }

        return this.user.username === null || this.user.username === undefined || this.user.username === "" || this.user.username.length <= 0 || this.user.username.length > 10 ?
        "invalid-field" :
        "valid-field";

      },
      invalidFirstNameFieldValue() {

        if(!this.fields.firstName) {
          return "";
        }

        return this.user.firstName === null ||
        this.user.firstName === undefined ||
        this.user.firstName.length === "" ||
        this.user.firstName.length <= 1 || 
        !/^[A-Z]/.test(this.user.firstName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidLastNameFieldValue() {

        if(!this.fields.lastName) {
          return "";
        }

        return this.user.lastName === null ||
        this.user.lastName === undefined ||
        this.user.lastName.length === "" ||
        this.user.lastName.length <= 1 ||
        !/^[A-Z]/.test(this.user.lastName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidSocialIdFieldValue() {

        if(!this.fields.socialId) {
          return "";
        }

        return this.user.socialId === null || this.user.socialId === undefined || this.user.socialId.length === "" || this.user.socialId.length !== 14 ?
        "invalid-field" :
        "valid-field";

      },
      invalidEmailFieldValue() {

        if(!this.fields.email) {
          return "";
        }

        const emailSpecialCharIndex = this.user.email.indexOf("@");
        const emailDotCharIndex = this.user.email.indexOf(".");

        return this.user.email === null ||
        this.user.email === undefined ||
        this.user.email === "" ||
        this.user.email.length <= 0 || 
        emailSpecialCharIndex <= 0 || 
        emailSpecialCharIndex >= emailDotCharIndex - 1 || 
        emailDotCharIndex >= this.user.email.length - 1 ?
        "invalid-field" :
        "valid-field";

      },
      resetPassword() {

        if(this.user.email !== null && this.user.email !== undefined) {

          const body = {
            email: this.user.email,
          }

          sendResetPasswordToken(body).then(() => {

            ElMessage({
              message: 'Um e-mail será enviado com as instruções necessarias!',
              type: 'success'
            });

            }).catch(error => {

            if(this.hasBadRequestMessage(error)) {

              ElMessage({
                message: error.response.data._embedded.errors[0].message,
                type: 'error'
              });

            } else {
              ElMessage({
                message: "Ocorreu um erro ao tentar enviar o e-mail de recuperação de senha, por favor tente novamente mais tarde!",
                type: 'error'
              });
            }

          });

        }

      },
      updateUser() {

        if(this.validRequest(this.user)) {

          if(!this.imageChanged) {
            this.user.photoBase64 = null;
          }

          this.user.socialId = this.user.socialId.replaceAll(".", "").replaceAll("-", "");
          updateUser(this.user).then(() => {

            ElMessage({
              message: 'Usuário atualizado com sucesso!',
              type: 'success'
            });
            refreshTokenAndGetUserInfo();

          }).catch(() => {
            ElMessage({
              message: 'Usuário não pode ser atualizado, tente novamente mais tarde!',
              type: 'error'
            });
          });

        }

      },
      getUser() {

        const userIdentifier = getUserIdentifierFromCache();
        if(userIdentifier !== null) {

          getUserInfo().then(response => {
            this.user = response.data;
            this.getUserCompaniesConfig();
          }).catch(() => this.$router.push({ path: HOME_PATH }));

        } else {
          this.$router.push({ path: HOME_PATH });
        }

      },
      uploadImage(e){

        const image = e.raw;
        this.fileName = e.name;
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            const previewImage = e.target.result;
            const base64 = previewImage.split(',');
            this.user.image = {
              base64: base64[1]
            };
            this.user.photoBase64 = base64[1];
            this.imageChanged = true;
        };

      },
      removeImage() {
        this.user.image = null;
        this.user.photoBase64 = null;
        this.fileName = "";
        this.fileList = [];
      },
      validRequest(user) {

        if(user.username === null || user.username === undefined || user.username === "" || user.username.length <= 0 || user.username.length > 10) {
          return false;
        }

        if(user.firstName === null ||
          user.firstName === undefined ||
          user.firstName.length === "" ||
          user.firstName.length <= 1 || 
          !/^[A-Z]/.test(user.firstName)) {
          return false;
        }

        if(user.lastName === null ||
          user.lastName === undefined ||
          user.lastName.length === "" ||
          user.lastName.length <= 1 ||
          !/^[A-Z]/.test(user.lastName)) {
          return false;
        }

        if(user.socialId === null || user.socialId === undefined || user.socialId.length === "" || user.socialId.length !== 14) {
          return false;
        }

        const emailSpecialCharIndex = user.email.indexOf("@");
        const emailDotCharIndex = user.email.indexOf(".");
        if(user.email === null ||
          user.email === undefined ||
          user.email === "" ||
          user.email.length <= 0 || 
          emailSpecialCharIndex <= 0 || 
          emailSpecialCharIndex >= emailDotCharIndex - 1 || 
          emailDotCharIndex >= user.email.length - 1) {
          return false;
        }

        return true;

      },
      getUserCompaniesConfig() {
        this.configTableLoading = true;
        getUserCompaniesConfig().then(response => {
            this.companiesTypesConfigs = response.data;
            this.configTableLoading = false;
        }).catch(() => this.configTableLoading = false);
      },
      updateUserCompaniesConfig(companyConfig) {
        updateUserCompaniesConfig(companyConfig).then(() => {

            ElMessage({
                message: 'Configuração atualizada com sucesso!',
                type: 'success'
            });

            this.getUserCompaniesConfig();

        }).catch(() => {
            ElMessage({
                message: 'Configuração não pode ser atualizada, tente novamente mais tarde!',
                type: 'error'
            });
        });
      },
      getPtCurrency() {
        return { currency: "BRL" };
      },
      getType(type) {
        return CompanyType[type].description;
      },
      getDollarValueFormatted() {
        return "Cotação atual " + getFormattedValue(this.companiesTypesConfigs.dollarValue, "pt_br");
      },
      getCompanyConfigPercentage(companyConfig) {
        const percentage = ((companyConfig.monthLimit * 100)/this.userCompanyConfigTotal).toFixed(2);
        return Math.round(percentage) + "%";
      }
    },
    computed: {
      getUserPhoto() {

        if(this.user.photoBase64 !== undefined && this.user.photoBase64 !== null && this.user.photoBase64 !== '') {
            return convertBase64ToImage(this.user.photoBase64);
        }

        if(this.user.photoUrl !== undefined && this.user.photoUrl !== null && this.user.photoUrl !== '') {
            return this.user.photoUrl;
        }

        return DEFAULT_AVATAR_IMAGE_URL;

      },
      companyTypeList() {
        return Object.keys(CompanyType);
      },
      userCompanyConfigList() {
          this.companiesTypesConfigs.companyTypeConfig.forEach(companyTypeConfig => {
            companyTypeConfig.tagTypeClassName = CompanyType[companyTypeConfig.type].tagTypeClassName;
            companyTypeConfig.edited = false;
          });
          return this.companiesTypesConfigs.companyTypeConfig;
      },
      userCompanyConfigTotal() {
        return this.userCompanyConfigList.reduce((partialSum, config) => {
          return partialSum + config.monthLimit;
        }, 0);
      }
    }
  }

</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-tag {
      width: 80%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width: 20%;
    }

  }

  .user-profile {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .form {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .update-el-input {
    float: left;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    flex-direction: column;
    font-weight: bold;
  }

  .name-el-input {
    font-weight: bold;
    width: 100%;
    margin-top: 5px;
  }

  .complete-name-el-input {
    font-weight: bold;
    width: 110%;
    margin-top: 5px;
    background-color: gray;
  }

  .common-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .photo-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }

  .avatar {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avatar:hover {
      cursor: pointer;
      border: 1px solid gray;
  }

  .foot-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 25px;
    width: 100%;
  }

  .company-config-input {
    margin-top: 50px;
  }

  .company-config-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-divider--vertical {
    height: 30em;
    border-left: 1px $default-secondary-color var(--el-border-style);
  }

  .el-divider--horizontal {
    border-top: 1px $default-secondary-color var(--el-border-style);
  }

  .reset-button {
    margin-bottom: 40px;
  }

  i {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }

</style>
@/http/user/anonymous-user-service@/http/bff/user-bff-service