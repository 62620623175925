<template>

    <div class="home-view">

        <div v-if="!isUserOn" class="home-login">

            <div class="left">

                <div id="logo-id" class="logo-text">
                    <img src="../../assets/logo.svg" width="100" height="100" class="img" @click="goToHome()">
                    <h1> Personal Invest </h1>
                </div>

                <h3 id="title-id" style="color: white;">
                    Bem-vindo(a) ao Personal Invest!
                </h3>

                <span id="credentials-id" > Digite suas credenciais para continuar </span>

                <login-section id="login-id"/>

            </div>

            <div class="right">
                <img src="../../assets/background.png" class="img background">
            </div>

        </div>

        <router-view></router-view>

    </div>

</template>

<script>

    import { convertBase64ToImage } from '../../util/AppUtils';
    import { isUserLogged, getUserFromCache } from '../../util/UserUtils';
    import Image from '../../models/Image';
    import User from '../../models/User';
    import LoginSection from '@/section/login/LoginSection.vue';

    export default {
        name: 'home-view',
        components: {
            LoginSection
        },
        data() {
            return {
                image: new Image(),
                user: new User()
            }
        },
        created() {

            this.setUser();
            if(isUserLogged()) {
                this.user = getUserFromCache();
            }

        },
        methods: {
            setUser() {
                this.emitter.on('setUser', (user) => {
                    this.user = user;
                });
            },
            goToLogin() {
                this.user = getUserFromCache();
                this.loginDialogVisible = true;
            },
            getDescription() {
                return this.isUserOn ? "Olá, " + this.user.name : "Realize o login";
            }
        },
        computed: {
            getImageUrl() {

                if(this.user !== null && this.user !== undefined && this.user.photoBase64 !== undefined && this.user.photoBase64 !== null && this.user.photoBase64 !== '') {
                    return convertBase64ToImage(this.user.photoBase64);
                }

                if(this.image.base64 !== undefined && this.image.base64 !== null && this.image.base64 !== '') {
                    return convertBase64ToImage(this.image.base64);
                }

                return "";

            },
            isUserOn() {
                return this.user !== null && this.user.id !== null;
            },
        }
    }

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .home-view{
            text-align: center;
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .home-login {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .left {
            width: 100%;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .right {
            width: 100%;
        }

        .logo-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .background {
            display: none;
        }

        #logo-id {
            order: 1;
        }

        #title-id {
            order: 2;
        }

        #credentials-id {
            order: 3;
        }

        #login-id {
            order: 4;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .home-view {
            text-align: center;
            width: 40%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .home-login {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .left {
            width: 40%;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .right {
            width: 100%;
        }

        .logo-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .background {
            border-radius: 25px;
            box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
            width: 100%;
            height: 800px;
        }

    }

    h1 {
        margin-left: 10px;
    }

    span {
        font-size: 12px;
    }

</style>
