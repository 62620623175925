<template>

  <div class="dashboard-view">

    <h1> Dashboard </h1>
    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }"
            @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
            Informações
          </span>
        </template>
        <section-row :isFirstSection="true">
          <banner-view v-if="banners.articles.length > 0" bannerTitle="Notícias" bannerTitleTooltip="Notícias do dia"
            :banners="banners.articles" :imagePrefix="imagePrefix" />
        </section-row>
        <section-row>
          <currency-summary-section />
        </section-row>

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }"
            @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Carteira
          </span>
        </template>
        <dashboard-summary-section />

      </el-tab-pane>

      <el-tab-pane name="3">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverThree, 'is-active': activeName === '3' }"
            @mouseover="isMouseOverThree = true" @mouseout="isMouseOverThree = false">
            Rentabilidade
          </span>
        </template>
        <profit-summary-section />

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()"> Voltar </button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

import SectionRow from '@/component/common/SectionRow';
import CurrencySummarySection from '@/section/dashboard/tab/news/CurrencySummarySection';
import DashboardSummarySection from '@/section/dashboard/tab/summary/DashboardSummarySection';
import ProfitSummarySection from '@/section/dashboard/tab/profit/ProfitSummarySection';
import BannerView from '../../layouts/Banner.vue';
import { getBanners } from '../../http/bff/dashboard-bff-service';

export default {
  name: 'dashboard-view',
  components: { SectionRow, CurrencySummarySection, DashboardSummarySection, ProfitSummarySection, BannerView },
  data() {
    return {
      imagePrefix: "https://br.advfn.com",
      banners: {
        count: 0,
        articles: []
      },
      activeName: '1',
      isMouseOverOne: false,
      isMouseOverTwo: false,
      isMouseOverThree: false,
      lastActiveName: '1'
    }
  },
  methods: {
    getBanners() {
      getBanners().then(response => this.banners = response.data);
    },
    handleChange() {
      this.lastActiveName = this.activeName;
      window.scroll(0, 0);
    },
    goBack() {

      const lastActiveName = this.activeName;

      this.activeName = this.lastActiveName;
      this.lastActiveName = lastActiveName;
      window.scroll(0, 0);

    }
  },
  created() {
    this.getBanners();
  },
  mounted() {
    document.title = "Dashboard - Personal Invest";
  }
}
</script>

<style lang="scss" scoped>
.dashboard-view {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.back-button {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>