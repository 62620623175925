import { httpConfiguration } from '../base-service';
import { getUserRefreshTokenCache } from '../../util/UserUtils';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/anonymous-user-service/v1');

export const login = (credentials) => http.post('/login', credentials);

export const refreshTokenRequest = () => {
  return http.post('/refresh-token', { "refreshToken": getUserRefreshTokenCache() });
}

export const createUser = (user) => {
  return http.post('/signup/', user);
};

export const sendResetPasswordToken = (body) => {
  return http.post('/user/password', body);
};

export const resetPasswordToken = (body) => {
  return http.put('/user/password', body);
};

export const activateUser = (activationToken) => {
  return http.put('/user/' + activationToken + '/activate', {});
};
