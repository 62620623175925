<template>

    <el-dialog v-model="isDialogVisible" :width="dialogWidth" :show-close="!isPhoneScreenWidth" :before-close="cancel">

        <template #header>
            <span class="header-text"> {{ formattedHeaderText }} </span>
        </template>

        <div v-if="isDialogVisible" class="dialog-body">
            <slot name="body"></slot>
        </div>

    </el-dialog>

</template>

<script>

    export default {
        name: 'default-dialog',
        props: {
            visible: Boolean,
            headerText: String
        },
        methods: {
            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },
        computed: {
            isDialogVisible() {
                return this.visible;
            },
            formattedHeaderText() {
                return this.capitalize(this.headerText);
            },
            isPhoneScreenWidth() {
                return window.screen.width < 1024;
            },
            dialogWidth() {
                return this.isPhoneScreenWidth ? "50%" : "30%";
            }
        }
    }

</script>

<style lang="scss">

    .header-text {
        color: $dialog-header-text-color;
    }

    .dialog-body {
        padding-left: 5px;
        padding-right: 5px;
    }

    // Element
    .el-dialog {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .el-dialog__header {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $dialog-background-color !important;
        padding: 0px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        margin-right: 0px !important;
    }

    .el-dialog__body {
        width: 100%;
        background-color: $dialog-background-color !important;
    }

    .el-dialog__headerbtn {
        margin-right: 10px !important;
    }

    @media screen and (max-width: 1024px) {
        
        .el-dialog__body {
            padding: 0px !important;
            padding-top: 20px !important;
            padding-bottom: 10px !important;
        }

        .el-dialog__headerbtn {
            margin-right: 10px !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .el-dialog__body {
            padding: 0px !important;
        }

    }

</style>