<template>

    <default-dialog :visible="loginDialogVisible"
        headerText="Realmente deseja ajustar o balanço?">
        <template #body>
            <login-section/>
        </template>
    </default-dialog>

    <div class="logout">

        <confirm-dialog :visible="logoutDialogVisible"
            headerText="Realmente deseja sair?"
            confirmText="Confirmar"
            cancelText="Cancelar"
            @confirm="logout()"
            @cancel="closeLogoutDialog()">
        </confirm-dialog>

    </div>

    <el-header>

        <el-menu :default-active="activeMenu" class="el-menu-demo header" mode="horizontal" menu-trigger="click" popper-effect="light">

            <el-menu-item index="1" class="menu-item logo">

                <div class="logo">
                    <img src="@/assets/logo.svg" width="70" height="70" class="img logo" @click="goToHome()">
                </div>

            </el-menu-item>

            <div class="text-menu-items">

                <el-menu-item index="2" class="menu-item">

                    <span @click="goToHome()" @click.middle="goToHomeWithMiddleClick()" class="header-item"> Personal Invest </span>

                </el-menu-item>

                <el-menu-item index="3" class="menu-item">

                    <span @click="goToStatusInvest()" @click.middle="goToStatusInvestWithMiddleClick()" class="header-item"> Status Invest </span>

                </el-menu-item>

                <el-menu-item index="4" class="menu-item">
                    <el-icon  v-if="getSensitiveDataFlag() === 'false'" @click="updateSensitiveDataFlag()" style="color: #409eff;"><View/></el-icon>
                    <el-icon v-if="getSensitiveDataFlag() === 'true'" @click="updateSensitiveDataFlag()" style="color: #409eff;"><NoSmoking/></el-icon>
                </el-menu-item>

                <el-menu-item index="5" class="menu-item search-menu-item">
                    <div class="company-search">
                        <el-input v-model="companyNameFilter" @keyup="getCompaniesWithText()" minlength="3" maxlength="6" name="company-name" placeholder="ex: PETR4" size="small" class="company-search-input">
                            <template #suffix>
                                <el-icon class="search-icon"><Search/></el-icon>
                            </template>
                        </el-input>
                        <el-select v-if="showCompaniesOptions" v-model="companyNameSearch" class="m-2 company-search-select" size="small" filterable>
                            <el-option v-for="company in companies" :key="company.name" :value="company.name" @click="goToCompanyDetails()">
                                <div class="company-option">
                                    <img :src="company.imageUrl" class="company-image-option">
                                    <span> {{ company.name }} </span>
                                </div>
                            </el-option>
                        </el-select>
                    </div>
                </el-menu-item>

            </div>

            <el-sub-menu v-if="this.user != null && this.user.id !== null" index="6" class="user-menu-item">

                <template #title>

                    <el-tooltip  class="box-item" effect="dark" placement="left">
                        <template #content>
                            <div class="tooltip">
                                <span> Olá, {{ getUser.name }} </span>
                            </div>
                        </template>
                        <el-avatar :size="50" :src="getUserPhoto" @click="goToUpdateUser()" class="avatar"/>
                    </el-tooltip>

                </template>
                <div>
                    <user-section :logout="showLogoutDialog" :goToUpdateUser="goToUpdateUser"/>                    
                </div>

            </el-sub-menu>

            <div class="user-menu-item">

                <el-menu-item v-if="showLoginButton()" index="3">
                    <el-button type="success" @click="openLoginDialog()"> ENTRAR </el-button>
                </el-menu-item>

            </div>

        </el-menu>

    </el-header>

</template>

<script>

    import { USER_PROFILE_PATH } from '@/constants/RoutesConstants';
    import { isUserLogged } from '@/util/UserUtils';
    import User from '@/models/User';
    import UserSection from './items/UserSection';
    import LoginSection from '@/section/login/LoginSection.vue';
    import { DASHBOARD_PATH, HOME_PATH, COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
    import { BLANK_PAGE_ARG, STATUS_INVEST_URL, DEFAULT_AVATAR_IMAGE_URL } from '@/constants/AppConstants';
    import { getUserFromCache, removeUserCache, removeUserTokenCache, removeUserRefreshTokenCache, removeDollarValueCache } from '@/util/UserUtils';
    import { updateSensitiveDataFlag, getSensitiveDataFlag } from  '@/util/LocalStorageUtils.js';
    import { getCompaniesByNameLike } from '@/http/bff/company-bff-service';
    import { NoSmoking } from '@element-plus/icons-vue';
    import Dialog from "@/component/common/Dialog";
    import ConfirmDialog from "@/component/common/ConfirmDialog";

    export default {
        name: 'header-view',
        components: { UserSection, LoginSection, NoSmoking, 'default-dialog': Dialog, ConfirmDialog },
        data() {
            return {
                user: new User(),
                loginDialogVisible: false,
                logoutDialogVisible: false,
                activeMenu: "1",
                companyNameFilter: "",
                companyNameSearch: null,
                companies: []
            }
        },
        created() {
            this.updateUser();
        },
        methods: {
            goToCompanyDetails() {
                if(this.companyNameSearch !== null) {
                    const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + this.companyNameSearch.toLowerCase();
                    window.open(formattedUrl, BLANK_PAGE_ARG).focus();
                    this.companyNameSearch = null;
                }
            },
            updateSensitiveDataFlag() {
                updateSensitiveDataFlag();
                this.$router.go();
            },
            getSensitiveDataFlag() {
                return getSensitiveDataFlag();
            },
            goToUpdateUser() {
                this.$router.push({ path: USER_PROFILE_PATH })
            },
            showLogoutDialog() {
                this.logoutDialogVisible = true;
            },
            updateUser() {

                this.emitter.on('setUserInfo', (user) => {
                    this.user = user;
                });

                const user = getUserFromCache();

                if(user !== null || user !== undefined) {
                    this.user = user;
                    this.emitter.emit('setUserStatus', true);
                    this.emitter.emit('setUser', this.user);
                    this.emitter.emit('setUserInfo', this.user);
                }
                
            },
            closeLoginDialog() {
                this.loginDialogVisible = false;
            },
            openLoginDialog() {
                this.loginDialogVisible = true;
            },
            goToHome() {
                if(isUserLogged()) {
                    this.$router.push({ path: DASHBOARD_PATH });
                    this.emitter.emit('setUserStatus', true);
                    this.emitter.emit('setUser', this.user);
                }
            },
            goToStatusInvest() {
                window.open(STATUS_INVEST_URL, BLANK_PAGE_ARG).focus();
            },
            goToHomeWithMiddleClick() {
                window.open(DASHBOARD_PATH, BLANK_PAGE_ARG).focus();
            },
            goToStatusInvestWithMiddleClick() {
                window.open(STATUS_INVEST_URL, BLANK_PAGE_ARG).focus();
            },
            logout() {
                this.user = new User();
                removeUserCache();
                removeUserTokenCache();
                removeUserRefreshTokenCache();
                removeDollarValueCache();
                this.emitter.emit('setUserStatus', false);
                this.emitter.emit('setUser', this.user);
                this.logoutDialogVisible = false;
                this.$router.push({ path: HOME_PATH });
            },
            closeLogoutDialog() {
                this.logoutDialogVisible = false;
            },
            showLoginButton() {
                return this.user != null && this.user.id === null && this.$route.path !== HOME_PATH;
            },
            getCompaniesWithText() {
                if(this.companyNameFilter !== null && this.companyNameFilter !==  undefined && this.companyNameFilter.length < 2) {
                    this.companies = [];
                } else if(this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6) {
                    getCompaniesByNameLike(this.companyNameFilter).then(response => {
                        this.companies = response.data.companies;
                    });
                }
            }
        },
        computed: {
            getUser() {
                return this.user;
            },
            getUserPhoto() {

                if(this.user.photoUrl !== undefined && this.user.photoUrl !== null && this.user.photoUrl !== '') {
                    return this.user.photoUrl;
                }

                return DEFAULT_AVATAR_IMAGE_URL;

            },
            showCompaniesOptions() {
                return this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6;
            }
        }
    }

</script>
  
<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .header {
            display: flex;                  /* establish flex container */
            flex-direction: row;            /* default value; can be omitted */
            flex-wrap: nowrap;              /* default value; can be omitted */
            justify-content: flex;
            width: inherit;
            text-align: center;
            background-color: $default-layout-color;
            font-weight: bold;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            position: fixed;
            width: 100%;
        }

        .text-menu-items {
            margin-left: 0px;
            display: flex;
            flex-direction: row;
            font-size: 10px;
        }

        .logo {
            margin-left: 10px;
            padding-right: 10px;
            margin-bottom: 10px;
        }

        .el-sub-menu {
            padding: 20px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .header {
            display: flex;                  /* establish flex container */
            flex-direction: row;            /* default value; can be omitted */
            flex-wrap: nowrap;              /* default value; can be omitted */
            justify-content: space-between;
            width: inherit;
            text-align: center;
            background-color: $default-layout-color;
            font-weight: bold;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            position: fixed;
            width: 100%;
        }

        .text-menu-items {
            margin: auto;
            margin-left: 30px;
            display: flex;
            flex-direction: row;
        }

        .logo {
            margin-left: 55px;
            padding-right: 45px;
            margin-bottom: 8px;
        }

        .company-search-input {
            width: 130px;
            height: 30px;
            margin-bottom: 2px;
        }

        .company-search-select {
            width: 150px;
            height: 30px;
        }

    }

    .el-header {
        --el-header-padding: 0px;
    }

    img {
        cursor: pointer;
        margin-top: 14px;
    }

    .header-item {
        cursor: pointer;
        color: $default-header-text-color;
    }

    .header-item:hover {
        color: white;
    }
    
    .tooltip {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .avatar:hover {
        cursor: pointer;
        border: 1px solid gray;
    }

    .el-menu--horizontal {
        border-bottom: 0px;
        padding: 0px !important;
        z-index: 3000;
    }

    .el-menu-item:hover {
        background-color: $default-layout-color !important;
    }

    .el-sub-menu__title .el-tooltip__trigger .el-tooltip__trigger {
        background-color: $default-layout-color !important;
        border: 0px !important;
    }

    .el-menu-popper {
        background-color: $default-layout-color !important;
        border: 1px solid red !important;
        padding: 0px !important;
    }

    .user-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 80px;
    }

    .user-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $default-layout-color;
        border: 1px solid $default-primary-title-text-color;
        min-width: 50px;
    }

    .el-sub-menu .user-menu-item:hover {
        background-color: $default-layout-color !important;
    }

    .logout {
        text-align: center;
    }

    .dialog-header {
        color: black !important;
        line-height: var(--el-dialog-font-line-height);
        font-size: var(--el-dialog-title-font-size);
        color: var(--el-text-color-primary);
    }

    .el-menu-item .is-active {
        border-bottom: 2px solid var(--el-menu-active-color);
    }
    
    .el-sub-menu:hover {
        border-bottom: 2px solid var(--el-menu-active-color) !important;
    }

    .search-menu-item {
        cursor: default;
    }
    
    .company-search {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .search-icon {
        color: gray !important;
        cursor: default;
    }

    .company-option {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2px;
    }

    .company-image-option {
        margin-top: 0px !important;
        width: 30px !important;
        height: 30px !important;
        margin-right: 5px;
    }

</style>
