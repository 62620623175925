<template>

    <div class="create-company-alert-dialog">

        <div class="company-search">
            <el-input v-model="companyNameFilter" @keyup="getCompaniesWithText()" minlength="3" maxlength="6" name="company-name" placeholder="ex: PETR4" size="small" class="company-search-input">
                <template #suffix>
                    <el-icon class="search-icon"><Search/></el-icon>
                </template>
            </el-input>
            <el-select v-if="showCompaniesOptions" v-model="companyNameSearch" class="m-2 company-search-select" size="small" filterable>
                <el-option v-for="company in companies" :key="company.id" :value="company" @click="setCompanyNewAlertAndGetCompanyDetails(company.name)">
                    <div class="company-option">
                        <img :src="company.imageUrl" class="company-image-option">
                        <span> {{ company.name }} </span>
                    </div>
                </el-option>
            </el-select>
        </div>

        <div v-if="companyAlert.company !== null" class="body-info">
            <div class="image-name">
                <img v-if="companyAlert.company.imageUrl != null" style="margin-right: 10px" :src="companyAlert.company.imageUrl" width="30" height="20">
                <span :class="getCompanyTagClassName()"> {{ companyAlert.company.name }} </span>
            </div>
        </div>

        <div v-if="companyAlert.company !== null" class="body-info">
            <span class="price"> Preço atual </span>
            <span v-loading="companyDetailsLoading" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">
                {{ getFormattedValueByType() }}
            </span>
        </div>

        <div v-if="companyAlert.company !== null" class="body-info">

            <span class="price"> <b> Tipo de alerta </b></span>
            <el-select v-model="companyAlert.type" class="m-2 company-alert-type-select" size="small" filterable>
                <el-option v-for="alertType in alertTypes" :key="alertType" :value="alertType" :label="getAlertTypeDescription(alertType)" @click="setCompanyNewAlert()">
                    <div class="company-alert-option">
                        <span> {{ getAlertTypeDescription(alertType) }} </span>
                    </div>
                </el-option>
            </el-select>

        </div>

        <div v-if="companyAlert.company !== null" class="body-info">

            <span class="price"> <b> Valor </b></span>
            <currency-input v-model="companyAlert.alertPrice" :options="getCompanyCurrency(companyAlert.company)" class="price-input" @blur="setCompanyNewAlert()"/>

        </div>

        <div v-if="companyAlert.company !== null" class="body-info">
            <span class="price"> <b> Notificação por e-mail </b></span>
            <el-checkbox v-model="companyAlert.sendEmail" @change="setCompanyNewAlert()"></el-checkbox>
        </div>

        <div v-if="companyAlert.company !== null" class="body-info">
            <span class="price"> <b> Notificação por Web Push </b></span>
            <el-checkbox v-model="companyAlert.sendWebPush" @change="setCompanyNewAlert()"></el-checkbox>
        </div>

    </div>

</template>

<script>

    import { DEFAULT_STOCK_INITIAL_VALUE, DEFAULT_STOCK_DECIMAL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
    import CompanyType from '@/constants/CompanyType';
    import CompanyAlertType from '@/constants/CompanyAlertType';
    import CurrencyInput from "@/component/common/CurrencyInput";
    import { getFormattedValue } from '@/util/MoneyUtils';
    import { getCompaniesByNameLike, getCompanyDetails } from '@/http/bff/company-bff-service';

    export default {
        name: 'create-company-alert-dialog',
        components: {
            CurrencyInput
        },
        data() {
            return {
                DEFAULT_STOCK_INITIAL_VALUE,
                DEFAULT_STOCK_DECIMAL,
                CompanyType,
                companyNameFilter: null,
                companyNameSearch: null,
                companies: [],
                companyAlert: {
                    type: CompanyAlertType.LOWER_THAN_OR_EQUAL.value,
                    company: null,
                    sendEmail: true,
                    sendWebPush: true,
                    alertPrice: null
                },
                selectedCompanyDetails: null,
                companyDetailsLoading: false,
                loadingSvg: DEFAULT_LOADING_SVG
            }
        },
        props: {
            currencyInputOptions: Function,
            setCompanyAlert: Function
        },
        methods: {
            getCompanyDetails(name) {
                this.companyDetailsLoading = true;
                getCompanyDetails(name, "DAY").then(response => {
                    this.selectedCompanyDetails = response.data;
                    this.companyAlert.alertPrice = this.selectedCompanyDetails.currentMarketValue;
                    this.companyDetailsLoading = false;
                }).catch(() => this.companyDetailsLoading = false);
            },
            setCompanyNewAlertAndGetCompanyDetails(name) {
                this.clearFilter();
                this.getCompanyDetails(name);
            },
            setCompanyNewAlert() {
                this.setCompanyAlert(this.companyAlert);
            },
            getCompanyCurrency(company) {
                return this.currencyInputOptions(company);
            },
            getCompanyAmountClass() {

                if(this.companyNameSearch === null) {
                    return "";
                }

                return this.companyNameSearch.type !== this.CompanyType.STOCK.value ? "integer-amount-input" : "double-amount-input";

            },
            getFormattedValueByType() {

                if(this.selectedCompanyDetails !== null && this.selectedCompanyDetails.type !== null) {
                    const locale = CompanyType[this.selectedCompanyDetails.type].locale;
                    return getFormattedValue(this.selectedCompanyDetails.currentMarketValue, locale);
                }

                return "-";

            },
            getFormattedValue(company) {

                if(company.type !== null) {
                    
                    const total = company.amount * company.price;
                    const locale = CompanyType[company.type].locale;

                    return getFormattedValue(total, locale);

                }

                return "-";

            },
            getAlertTypeDescription(type) {
                return CompanyAlertType[type].description;
            },
            getCompaniesWithText() {
                if(this.companyNameFilter !== null && this.companyNameFilter !==  undefined && this.companyNameFilter.length < 2) {
                    this.companies = [];
                } else if(this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6) {
                    getCompaniesByNameLike(this.companyNameFilter).then(response => {
                        this.companies = response.data.companies;
                    });
                }
            },
            getCompanyTagClassName() {
                return this.companyNameSearch !== null ? this.CompanyType[this.companyNameSearch.type].tagTypeClassName : "";
            },
            clearFilter() {
                this.companyNameFilter = null;
                this.companyAlert.company = this.companyNameSearch;
                this.selectedCompanyDetails = null;
                this.setCompanyNewAlert();
            }
        },
        computed: {
            alertTypes() {
                return Object.keys(CompanyAlertType);
            },
            showCompaniesOptions() {
                return this.companyNameFilter !== null && this.companyNameFilter !== undefined && this.companyNameFilter.length >= 2 && this.companyNameFilter.length <= 6;
            }
        }
    }

</script>

<style lang="scss">

    .create-company-alert-dialog {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center
    }

    .body-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px !important;
    }

    .amount {
        margin-bottom: 5px;
        text-align: start;
    }

    .price {
        margin-bottom: 5px;
        text-align: start;
    }

    .total {
        text-align: start;
    }

    .price-input {
        width: 60% !important;
        text-align: center;
    }

    .el-input-number {
        width: 60% !important;
    }

    .company-alert-option {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2px;
    }

    .image-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .company-search {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px !important;
    }

    .company-search-input {
        height: 30px;
        margin-bottom: 2px;
    }

    .company-option {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2px;
    }

    .company-image-option {
        margin-top: 0px !important;
        width: 30px !important;
        height: 30px !important;
        margin-right: 5px;
    }

    @media screen and (max-width: 1024px) {

        .amount {
            font-size: 8;
        }

        .price {
            font-size: 8;
        }

        .total {
            font-size: 8;
        }

        .price-input {
            padding: 6px 0px;
        }

        .company-alert-type-select {
            width: 150px;
            height: 30px;
        }

        .company-search {
            width: 60%;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .amount {
            font-size: 12;
        }

        .price {
            font-size: 12;
        }

        .total {
            font-size: 12;
        }

        .price-input {
            padding: 8px 0px;
        }

        .company-search-select {
            width: 100%;
            height: 30px;
        }

        .company-search {
            width: 30%;
        }

    }

</style>
