<template>
    
    <div class="company-summary-banner-section">

        <h3>
            DESTAQUES
            <el-tooltip class="box-item" effect="dark" content="Destaques da carteira" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div v-if="banners.length === 0">
            <EmptyResult></EmptyResult>
        </div>
        <el-carousel v-if="banners.length > 0" height="150px" :interval="5000" type="card" :autoplay="true" indicator-position="none">
            <el-carousel-item style="padding: 5px; border: 1px solid #409eff;" v-for="item in banners" :key="item">
                <img class="img" :src="getImageUrl(item)" width="80" height="80" @click="goToCompanyDetails(item)">
                <p class="carousel-item-company-name" @click="goToCompanyDetails(item)"> {{ item.name }} </p>
                <p class="carousel-item"> {{ item.titleType }} </p>
                <div class="carousel-item-value" :class="item.typeClassName">
                    <el-icon v-if="item.rawPriceDifference < 0" class="under-limit"><CaretBottom/></el-icon>
                    <el-icon v-if="item.rawPriceDifference >= 0" class="above-limit"><CaretTop/></el-icon>
                    <p v-if="item.rawPriceDifference >= 0" class="carousel-item"> + </p>
                    <p class="carousel-item"> {{ item.priceDifference }} </p>
                    <p class="type-percentage default-info type-total" style="margin-left: 2px;"> {{ item.priceDifferencePercentage }} </p>
                </div>
            </el-carousel-item>
        </el-carousel>

    </div>

</template>

<script>

    import { getFormattedValue } from '@/util/MoneyUtils';
    import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
    import { BLANK_PAGE_ARG } from '@/constants/AppConstants';
    import EmptyResult from '@/component/common/EmptyResult';

    export default {
        name: 'company-summary-banner-section',
        props: {
            companiesSummary: Object
        },
        components: {
            EmptyResult
        },
        methods: {
            getImageUrl(item) {
                return item.imageUrl;
            },
            getPtFormattedPrice(price) {
      
                if(price !== null && price !== undefined) {
                    return getFormattedValue(price, "pt_br");
                }

                return "-";

            },
            getDifferenceClass(type, value) {

                if(type !== null && type !== undefined) {
                    return value >= 0 ? "above-limit" : "under-limit";
                }

                return "";

            },
            goToCompanyDetails(company) {
                const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
                window.open(formattedUrl, BLANK_PAGE_ARG).focus();
            }
        },
        computed: {
            currentPage() {
                return this.pathsIndexs[this.$route.path];
            },
            banners() {
      
                let banners = [];
                if(this.companiesSummary.highestOpenPriceDifference != null) {
                    const highestOpenPriceDifferenceBanner = {
                        imageUrl: this.companiesSummary.highestOpenPriceDifference.imageUrl,
                        name: this.companiesSummary.highestOpenPriceDifference.name,
                        priceDifference: this.getPtFormattedPrice(this.companiesSummary.highestOpenPriceDifference.openPriceDifference),
                        rawPriceDifference: this.companiesSummary.highestOpenPriceDifference.openPriceDifference,
                        priceDifferencePercentage: this.companiesSummary.highestOpenPriceDifference.openPriceDifferencePercentage,
                        type: this.companiesSummary.highestOpenPriceDifference.type,
                        titleType: "Maior lucro hoje",
                        typeClassName: this.getDifferenceClass(this.companiesSummary.highestOpenPriceDifference.type, this.companiesSummary.highestOpenPriceDifference.openPriceDifference)
                    };
                    banners.push(highestOpenPriceDifferenceBanner);
                }

                if(this.companiesSummary.lowestOpenPriceDifference != null) {
                    const lowestOpenPriceDifferenceBanner = {
                        imageUrl: this.companiesSummary.lowestOpenPriceDifference.imageUrl,
                        name: this.companiesSummary.lowestOpenPriceDifference.name,
                        priceDifference: this.getPtFormattedPrice(this.companiesSummary.lowestOpenPriceDifference.openPriceDifference),
                        rawPriceDifference: this.companiesSummary.lowestOpenPriceDifference.openPriceDifference,
                        priceDifferencePercentage: this.companiesSummary.lowestOpenPriceDifference.openPriceDifferencePercentage,
                        type: this.companiesSummary.lowestOpenPriceDifference.type,
                        titleType: "Maior prejuízo hoje",
                        typeClassName: this.getDifferenceClass(this.companiesSummary.lowestOpenPriceDifference.type, this.companiesSummary.lowestOpenPriceDifference.openPriceDifference)
                    };
                    banners.push(lowestOpenPriceDifferenceBanner);
                }
                
                if(this.companiesSummary.highestPriceDifference != null) {
                    const highestPriceDifferenceBanner = {
                        imageUrl: this.companiesSummary.highestPriceDifference.imageUrl,
                        name: this.companiesSummary.highestPriceDifference.name,
                        priceDifference: this.getPtFormattedPrice(this.companiesSummary.highestPriceDifference.priceDifference),
                        rawPriceDifference: this.companiesSummary.highestPriceDifference.priceDifference,
                        priceDifferencePercentage: this.companiesSummary.highestPriceDifference.priceDifferencePercentage,
                        type: this.companiesSummary.highestPriceDifference.type,
                        titleType: "Maior lucro da carteira",
                        typeClassName: this.getDifferenceClass(this.companiesSummary.highestPriceDifference.type, this.companiesSummary.highestPriceDifference.priceDifference)
                    };
                    banners.push(highestPriceDifferenceBanner);
                }

                if(this.companiesSummary.lowestPriceDifference != null) {
                    const lowestPriceDifferenceBanner = {
                        imageUrl: this.companiesSummary.lowestPriceDifference.imageUrl,
                        name: this.companiesSummary.lowestPriceDifference.name,
                        priceDifference: this.getPtFormattedPrice(this.companiesSummary.lowestPriceDifference.priceDifference),
                        rawPriceDifference: this.companiesSummary.lowestPriceDifference.priceDifference,
                        priceDifferencePercentage: this.companiesSummary.lowestPriceDifference.priceDifferencePercentage,
                        type: this.companiesSummary.lowestPriceDifference.type,
                        titleType: "Maior prejuízo da carteira",
                        typeClassName: this.getDifferenceClass(this.companiesSummary.lowestPriceDifference.type, this.companiesSummary.lowestPriceDifference.priceDifference)
                    };
                    banners.push(lowestPriceDifferenceBanner);
                }

                return banners;
                
            }
        }
    }

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {

        .el-carousel__item h3 {
            font-size: 8px;
        }

        .carousel-item-company-name {
            font-size: 10px;
            font-weight: bold;
            margin: 0px;
        }

        .carousel-item {
            font-weight: bold;
            font-size: 8px;
            margin-bottom: 5px;
        }

        .type-percentage {
            font-size: 6px !important;
            font-style: italic !important;
            margin-bottom: 6px;
        }

        .type-total {
            margin: 5px;
            font-size: 7px;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .el-carousel__item h3 {
            font-size: 13px;
        }

        .carousel-item-company-name {
            font-size: 13px;
            font-weight: bold;
            margin: 0px;
            margin-bottom: 5px;
        }

        .carousel-item {
            font-weight: bold;
            font-size: 13px;
            margin: 0px;
        }

        .type-percentage {
            font-size: 10px !important;
            font-style: italic !important;
        }

        .type-total {
            margin: 5px;
            font-size: 13px;
        }

    }

    .company-summary-banner-section {
        width: 100%;
        margin-right: 5px;
    }

    .img {
        margin: 5px;
        border-radius: 5px;
    }

    .el-carousel__item {
        border-radius: 5px;
    }

    .el-carousel__item h3 {
        color: #409eff;
        background-color: $default-secondary-color;
        line-height: 200px;
        margin: 5px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }
    
    .el-carousel__item:nth-child(2n) {
        background-color: $default-secondary-color;
        color: #409eff;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: $default-secondary-color;
        color: #409eff;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-weight: bold;
    }

    .el-carousel--horizontal {
        overflow-x: clip;
    }

    .carousel-item-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .default-info {
        font-weight: bold;
    }

</style>
