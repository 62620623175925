<template>

    <div class="login">

        <div class="login-input">
            <el-select v-model="selectedLoginType" @change="handleLoginTypeChange()" class="m-2" placeholder="Selecione uma forma de login" size="small">
                <el-option v-for="loginType in getLoginTypes" :key="loginType" :label="getLoginTypeText(loginType)" :value="loginType"/>
            </el-select>
            <el-input v-if="selectedLoginType !== 'SOCIAL_ID'" v-model="user.username" @change="initFieldEdit('username')" :class="invalidUsernameFieldValue()" minlength="1" :maxlength="getUsernameInputMaxLength()" form="login-form" name="username-input" :placeholder="getPlaceholder()" size="small" tabindex="1" @keyup.enter="login()" clearable/>
            <el-input v-if="selectedLoginType === 'SOCIAL_ID'" v-model="user.username" v-maska data-maska="###.###.###-##" @change="initFieldEdit('username')" :class="invalidUsernameFieldValue()" minlength="1" :maxlength="getUsernameInputMaxLength()" form="login-form" name="username-input" :placeholder="getPlaceholder()" size="small" tabindex="1" @keyup.enter="login()" clearable/>
        </div>

        <div class="login-input">
            <span> Senha: </span>
            <el-input v-model="user.password" @change="initFieldEdit('password')" :class="invalidPasswordFieldValue()" minlength="6" maxlength="12" type="password" size="small" @keyup.enter="login()" tabindex="2" clearable show-password/>
        </div>

        <div class="login-actions">
            <span class="new-password" tabindex="4" @click="resetPassword()"> Esqueceu sua senha? </span>
        </div>

        <span class="signin">
            <el-button tabindex="3" v-loading="loginLoading" :element-loading-svg="loadingSvg" element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" type="success" @click="login()" :disabled="!validRequest() || loginLoading" class="login-button"> Entrar </el-button>
        </span>

        <div class="register-actions">

            <span> Ainda não possui uma conta? </span>

            <span class="dialog-footer signup" tabindex="5" @click="register()"> Faça já o seu cadastro! </span>

        </div>

    </div>

</template>

<script>

    import { login } from '../../http/user/anonymous-user-service';
    import { getUserInfo } from '../../http/bff/user-bff-service';
    import { getUserFromCache, setUserTokens, addUserCache } from '../../util/UserUtils';
    import { ElMessage } from 'element-plus'
    import { CREATE_USER_PATH, DASHBOARD_PATH, RESET_USER_PASSWORD_TOKEN_PATH } from '../../constants/RoutesConstants';
    import { DEFAULT_LOADING_SVG } from '../../constants/AppConstants';
    import User from '../../models/User';

    export default {
        name: 'login-module',
        data() {
            return {
                user: {
                    username: "",
                    password: ""
                },
                userInfo: new User(),
                loginLoading: false,
                loadingSvg: DEFAULT_LOADING_SVG,
                loginType: {
                    USERNAME: {
                        description: "Usuário"
                    },
                    SOCIAL_ID: {
                        description: "CPF"
                    },
                    EMAIL: {
                        description: "E-mail"
                    }
                },
                selectedLoginType: "USERNAME",
                fields: {
                    username: false,
                    password: false
                }
            }
        },
        methods: {
            getUserInfo() {
                
                const user = getUserFromCache();

                if(user === null || user === undefined) {
                    getUserInfo().then(userInfo => {
                        this.userInfo = userInfo.data;
                        this.userInfo.id = userInfo.data.userIdentifier;
                        addUserCache(this.userInfo);
                        this.emitter.emit('setUserStatus', true);
                        this.emitter.emit('setUser', this.userInfo);
                        this.emitter.emit('setUserInfo', this.userInfo);
                        this.loginLoading = false;
                        this.$router.push({ path: DASHBOARD_PATH });
                    });
                } else {
                    this.userInfo = user;
                    this.userInfo.id = user.data.userIdentifier;
                    this.emitter.emit('setUserStatus', true);
                    this.emitter.emit('setUser', this.userInfo);
                    this.emitter.emit('setUserInfo', this.userInfo);
                    this.loginLoading = false;
                }

            },
            login() {

                if(this.validRequest()) {

                    const username = this.user.username.replaceAll(".", "").replaceAll("-", "");
                    const userCredentials = {
                        username: username,
                        password: btoa(this.user.password),
                        type: this.selectedLoginType
                    };
                    this.loginLoading = true;
                    login(userCredentials).then(authenticationResponse => {

                        const userToken = authenticationResponse.data;
                        setUserTokens(userToken);
                        this.getUserInfo(userToken.userIdentifier);
                        this.loginLoading = false;

                    }).catch(() => {
                        ElMessage({
                            message: 'Erro ao logar, usuario ou senha invalido(s)!',
                            type: 'error'
                        });
                        this.loginLoading = false;
                    })

                }
                
            },
            register() {
                this.$router.push({ path: CREATE_USER_PATH });
            },
            resetPassword() {
                this.$router.push({ path: RESET_USER_PASSWORD_TOKEN_PATH });
            },
            getLoginTypeText(type) {
                return this.loginType[type].description;
            },
            getPlaceholder() {
                return "Informe o " + this.loginType[this.selectedLoginType].description.toLowerCase();
            },
            getUsernameInputMaxLength() {

                if(this.selectedLoginType === "USERNAME") {
                    return "10";
                } else if(this.selectedLoginType === "SOCIAL_ID") {
                    return 14;
                } else {
                    return 255;
                }

            },
            validRequest() {
                
                if(this.selectedLoginType === "USERNAME") {
                    
                    if(this.user.username === null ||
                        this.user.username === undefined ||
                        this.user.username === "" ||
                        this.user.username.length <= 0 ||
                        this.user.username.length > 10) {
                        return false;
                    }

                } else if(this.selectedLoginType === "SOCIAL_ID") {

                    if(this.user.username === null ||
                        this.user.username === undefined ||
                        this.user.username.length === "" ||
                        this.user.username.length !== 14) {
                        return false;
                    }

                } else {
                    
                    const emailSpecialCharIndex = this.user.username.indexOf("@");
                    const emailDotCharIndex = this.user.username.indexOf(".");

                    if(this.user.username === null ||
                        this.user.username === undefined ||
                        this.user.username === "" ||
                        this.user.username.length <= 0 || 
                        emailSpecialCharIndex <= 0 || 
                        emailSpecialCharIndex >= emailDotCharIndex - 1 || 
                        emailDotCharIndex >= this.user.username.length - 1) {
                        return false;
                    }

                }

                if(this.user.password === null ||
                    this.user.password === undefined ||
                    this.user.password === "" ||
                    this.user.password.length <= 0) {
                    return false;
                }

                return true;

            },
            initFieldEdit(field) {
                this.fields[field] = true;
            },
            invalidUsernameFieldValue() {

                if(!this.fields.username) {
                    return "";
                }

                if(this.selectedLoginType === "USERNAME") {
                    return this.user.username === null ||
                            this.user.username === undefined ||
                            this.user.username === "" ||
                            this.user.username.length <= 0 ||
                            this.user.username.length > 10 ?
                        "invalid-field" :
                        "valid-field";
                } else if(this.selectedLoginType === "SOCIAL_ID") {
                    return this.user.username === null ||
                            this.user.username === undefined ||
                            this.user.username.length === "" ||
                            this.user.username.length !== 14 ?
                            "invalid-field" :
                            "valid-field";
                } else {
                    
                    const emailSpecialCharIndex = this.user.username.indexOf("@");
                    const emailDotCharIndex = this.user.username.indexOf(".");

                    return this.user.username === null ||
                            this.user.username === undefined ||
                            this.user.username === "" ||
                            this.user.username.length <= 0 || 
                            emailSpecialCharIndex <= 0 || 
                            emailSpecialCharIndex >= emailDotCharIndex - 1 || 
                            emailDotCharIndex >= this.user.username.length - 1 ?
                            "invalid-field" :
                            "valid-field";

                }

            },
            invalidPasswordFieldValue() {

                if(!this.fields.password) {
                    return "";
                }

                return this.user.password === null ||
                        this.user.password === undefined ||
                        this.user.password === "" ||
                        this.user.password.length <= 0 ?
                        "invalid-field" :
                        "valid-field";

            },
            handleLoginTypeChange() {
                this.user.username = "";
                this.fields.username = false;
            }
        },
        computed: {
            getLoginTypes() {
                return Object.keys(this.loginType);
            }
        }
    }

</script>

<style lang="scss" scoped>

    @media screen and (max-width: 1024px) {
        .el-select {
            width: 50%;
            margin-bottom: 10px;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {
        .el-select {
            width: 30%;
            margin-bottom: 10px;
        }
    }

    .login {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: white !important;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .login-input {
        width: 80%;
        --color: var(--el-input-text-color,var(--el-text-color-regular));
        margin-bottom: 20px;
        text-align: start;
        float: left;
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        flex-direction: column;
        font-weight: bold;
    }

    .login-button {
        width: 80%;
        height: 45px;
    }

    .signin {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .login-actions {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: right;
    }

    .register-actions {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .new-password {
        cursor: pointer;
        color: $default-primary-title-text-color;
    }

    .register-text {
        background-color: red !important;
    }

    .signup {
        margin-top: 10px;
        color: $default-primary-title-text-color;
        cursor: pointer;
    }

    input {
        text-align: left !important;
    }

</style>
../../http/user/anonymous-user-service../../http/bff/user-bff-service