import { httpConfiguration } from '../base-service';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/user-bff-service/v1');

export const getUserInfo = () => http.get('/user');

export const updateUser = (user) => {
  return http.put('/user/', user);
};

export const getUserCompaniesConfig = () => http.get('/company/config');

export const updateUserCompaniesConfig = (userCompanyConfig) => http.put('/company/config', userCompanyConfig);

// notification = { 'token': 'ey...' }
export const setUserNotificationToken = (notification) => http.post('/user/notification', notification);
