export default {
    ACAO: {
        value: "ACAO",
        description: "Ações",
        locale: "pt_br",
        className: "acao-company-type-class",
        color: "#fb2a36",
        tagTypeClassName: "acao-tag-company-type-class"
    },
    FII: {
        value: "FII",
        description: "FII`s",
        locale: "pt_br",
        className: "fii-company-type-class",
        color: "#207cb4",
        tagTypeClassName: "fii-tag-company-type-class"
    },
    BDR: {
        value: "BDR",
        description: "BDR`s",
        locale: "pt_br",
        className: "bdr-company-type-class",
        color: "#03a94e",
        tagTypeClassName: "bdr-tag-company-type-class"
    },
    STOCK: {
        value: "STOCK",
        description: "Stocks",
        locale: "us",
        className: "stock-company-type-class",
        color: "#8d1920",
        tagTypeClassName: "stock-tag-company-type-class"
    },
    FIAGRO: {
        value: "FIAGRO",
        description: "FIAGRO`s",
        locale: "pt_br",
        className: "fiagro-company-type-class",
        color: "#07702E",
        tagTypeClassName: "fiagro-tag-company-type-class"
    },
    ETF: {
        value: "ETF",
        description: "ETF`s",
        locale: "pt_br",
        className: "etf-company-type-class",
        color: "#006A8A",
        tagTypeClassName: "etf-tag-company-type-class"
    }
}
