import { httpConfiguration } from '../base-service';

const http = httpConfiguration('http://intrainvest-api.ddns.net/api/transaction-bff-service/v1');

export const getOrders = (offset = 0, limit = 10, sorts = [], filter = {}) => {

  let params =  { offset, limit, sorts: [], status: [], types: [], companyTypes: [], startDateAt: "null", endDateAt: "null" };

  if(sorts.length > 0) {
    params["sorts"] = sorts.toString();
  }

  if(filter.selectedStatus.length > 0) {
    params["status"] = filter.selectedStatus.toString();
  }

  if(filter.selectedTypes.length > 0) {
    params["types"] = filter.selectedTypes.toString();
  }

  if(filter.selectedCompanyTypes.length > 0) {
    params["companyTypes"] = filter.selectedCompanyTypes.toString();
  }

  if(filter.searchText !== null && filter.searchText !== undefined && filter.searchText.length > 0) {
    params["searchText"] = filter.searchText;
  }

  if(filter.startDateAt !== null) {
    params["startDateAt"] = filter.startDateAt;
  }

  if(filter.endDateAt !== null) {
    params["endDateAt"] = filter.endDateAt;
  }

  return http.get('/order', { params });

};

export const get = (id) => {
  return http.get('/order/' + id);
};

export const updateOrder = (order, id) => http.put('/order/' + id, order);

export const deleteOrder = (id, status) => http.delete('/order/' + id, { headers: { 'status': status } });

export const importOrders = (body) => http.post('/order/import', body);
