export const DEFAULT_TOKEN_HEADER = "x-invest-api-token";
export const DEFAULT_REQUEST_TIMEOUT = 60000;
export const DEFAULT_SORT_DIVISOR = "###";
export const DEFAULT_STOCK_INITIAL_VALUE = 0.0000001;
export const DEFAULT_STOCK_DECIMAL = 8;
export const COMMA = ",";
export const DOT = ".";
export const BLANK_PAGE_ARG = "_blank";
export const STATUS_INVEST_URL = "https://statusinvest.com.br/carteira/patrimonio";
export const STATUS_INVEST_COMPANY_PAGE_URL = "https://statusinvest.com.br/${companyType}/${companyName}";
export const DEFAULT_AVATAR_IMAGE_URL = "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png";
export const DEFAULT_COMPANY_IMAGE_NAME = "@/assets/default-company-image.png";
export const DEFAULT_LOADING_SVG = `
                                    <path class="path" d="
                                    M 30 15
                                    L 28 17
                                    M 25.61 25.61
                                    A 15 15, 0, 0, 1, 15 30
                                    A 15 15, 0, 1, 1, 27.99 7.5
                                    L 15 15
                                    " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
                                `